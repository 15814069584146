import React from 'react'
import PropTypes from 'prop-types';

const Faqs = ({children}) => {
    return (
        <div>
            { children }
        </div>
    )
}
Faqs.propTypes = {
    children: PropTypes.node
  };
  
export default Faqs
