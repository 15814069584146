import React, { useState } from 'react'
import PropTypes from 'prop-types';

const Accordion = ({title,content}) => {
    const [setActive, setActiveState] = useState("");

    const toggleAccordion = () => {
        setActiveState(setActive === "" ? "active" : "")
    }
    return (
        <div className={`faq-box ${setActive}`}>
        <div className="faq-title" onClick={toggleAccordion}> 
            <div className="faq-toggle">
                <span></span>
                <span className="rot"></span>
            </div>
            <h2>{title} </h2> 
        </div>
        <div className="faq-answer" dangerouslySetInnerHTML={{ __html: content }}>            
        </div>
    </div>
    )
}

Accordion.propTypes = {
    title: PropTypes.string,
    content:PropTypes.node
  };

export default Accordion
