import React from 'react';

import SEO from 'components/seo';
import Layout from 'components/Layout';
import Hero from 'components/Hero';
import ContactMe from 'components/ContactMe' ;
import Article from 'components/Article';
import Faqs from 'components/Faqs';
import Accordion from 'components/Accordion';

const FAQ = () => {
  return (
    <Layout pageName="faq">
      <SEO title="Frequently Asked Questions" />
      <Hero 
        title="FAQ"        
        ></Hero>
      <Article>
        <Faqs>
          <Accordion 
            title="WHAT IS OSTICKET THEME?"
            content="<p>What is osticket theme? Is it a theme like what we have for WordPress and other CMS solutions? Can I just install this theme on osTicket software? These are few common questions that I get asked often. Here I am hoping to answer them to the best I can. The products you see on this site are customized osTicket software. That means, the source files of the osTicket client system is modified and additional CSS file are added to achieve the custom look and layout and responsive function.  They are NOT standalone template files. osTicket does not have a template engine, so provision of such installable theme files are not possible at the moment. In future osticket may come up with a template engine, but until then customizing the source files are the only way to modify the style and layout of osTicket.</p>">
          </Accordion>
          <Accordion 
            title="IS THE ADMIN SECTION ( BACKEND) IS ALSO CUSTOMIZED TO BE RESPONSIVE?"
            content="<p>Short answer is, No. The admin section of the osTicket is untouched in these custom themes. The customization is limited to the client section or front-end of the osTicket.</p> ">
          </Accordion>
          <Accordion 
            title="HOW TO INSTALL THIS?"
            content="
            <p>The download pack you receive upon purchase includes all the osTicket files along with the customized files. The installtion is just the same as you would install default osTicket software. </p>
            
            <h3> Fresh Installation </h3>
            <p>Uncompress the download pack and upload the files and directories in <i>upload</i> folder to a directory of your choice on your server. For example <i>/osticket/, /helpdesk/ </i> or <i>/support/</i> etc, depending on your preference. osTicket installer would require permission to write and modify the <i>ost-config.php</i> file located in the <i>include</i> directory. So make sure you have enabled proper file permissions. Open your osticket site on yor favorite browser and you will be taken to the installation page. Then proceed by following the instructions given on the installation page and finish the installation.</p>

            <h3> Applying theme on pre-existing osTicket installation </h3>

            <p> If you already have an osticket site up and running and want to enable the custom theme please follow the following steps. Imagine in the following scenario you have installed the osTicket in a directory named <i>/support/</i> on your server.</p>
            <ul>
            <li>Take a backup of the <i>/support/</i> directory on your computer </li>
            <li>Take a copy of the <i>ost-config.php</i> file from the include directory of support </li>
            <li>Remove or rename the <i>/support/</i> directory from your server</li>
            <li>Upload the osticket theme to your server and rename it to <i>/support/</i></li>
            <li>Replace the <i>ost-config.php</i> file in the theme with the original <i>ost-config.php</i> file taken earlier. </li>
            </ul>
            ">
          </Accordion>
          <Accordion 
            title="WHAT ABOUT UPDATES?"
            content="<p>Yes, updates are made to the osTicket themes when there is major update with the osTicket software.</p>
            ">
          </Accordion>
          <Accordion 
            title="IS IT CUSTOMIZABLE?"
            content="<p>Yes, there are customizable section in the themes. Stuff like logo, hero images, social media links, address lines etc are included in this. The documentation will come with directions on how to do these customizations. Customers can do deep customizations on other elements at their own risk, as  I will not support issues arising from those edits/customizations.  </p>">
          </Accordion>
        </Faqs>
      </Article>
      <ContactMe></ContactMe>
    </Layout>
  );
};

export default FAQ;